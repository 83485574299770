import React from 'react'
import { Container, Grid } from '@mui/material'
import Logo from '../../img/Dad.svg'
import Igicon from '../../img/Icons/instagram-icon.svg'
import Fbicon from '../../img/Icons/facebook-icon.svg'

// import Igicon from "../../../public/img/Instagram.png";
import Twicon from '../../img/Twitter.png'
// import Fbicon from "../../../public/img/facebook-icon.png";
// import Yticon from "../../../public/img/Youtube.png";
import { Box, styled } from '@mui/material'

const Footer = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'black',
      }}
    >
      <p style={{ color: 'white', fontFamily: 'Heebo' }}>
        © 2024 Date A Desi . All rights reserved.
      </p>
    </div>
  )
}

export default Footer
