import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import './App.css'
import { AboutUs } from './components/AboutUs/AboutUs'
import { ContactUs } from './components/ContactUs/ContactUs'
import Navbar from './components/Home/NavBar'
import './styles/globals.css'
import './styles/landingpage.css'
import './styles/layout.scss'
import theme from './theme'
function App() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer />
        <Routes>
          <Route path='/' Component={Navbar} />
          <Route path='/aboutus' Component={AboutUs} />
          <Route path='/contactus' Component={ContactUs} />
        </Routes>
      </ThemeProvider>
    </div>
  )
}

export default App
